.contanier {
    margin: 0 auto;
    width: 1500px;
    text-align: left;
    padding-left: 20px;
    box-sizing: border-box;
    // background-color: pink;
    .options {
        margin: 20px 0 20px 20px;
    }

    .time {
        font-size: 30px;
        text-align: right;
    }

    .exam-wrapper {
        margin-bottom: 50px;
        width: 1100px;
    }

    .part-title {
        font-size: 30px;
    }

    .ques {
        margin-bottom: 20px;
    }
}

.ant-radio-wrapper {
    margin-bottom: 30px;
}

.ant-radio {
    top: 8px;
}

.login-contanier {
    padding-top: 200px;
    // background-color: pink;
    margin: 0 auto;
    // width: 1500px;
    text-align: center;
    box-sizing: border-box;

    // .ant-form {
    //     position: absolute;
    //     left: 50%;
    //     // margin-left: -375px;
    // }
    .from {
        margin: 0 auto;
    }

    .ant-row {
        position: relative;
        left: 50%;
        margin-left: -200px;
        width: 1000px !important;
    }

    .ant-btn-primary {
        position: relative;
        left: -110px;
    }

    img {
        margin-left: -30px;
        margin-bottom: 100px;
        width: 408px;
        height: 101px;
    }

}

.index-contanier {
    margin: 0 auto;
    padding-top: 30px;
    width: 1200px;

    .large {
        font-size: 30px;
        line-height: 10px;
        font-weight: 500;
    }

    .small {
        font-size: 20px;
    }

    .wrap {
        margin-left: 120px;
        // width: 100%;
        // margin: 0 auto;
    }

    .red {
        width: 1000px;
        text-align: left;
        font-size: 20px;
        color: red;
    }

    p {}

    .btn-wrap {
        display: flex;
        justify-content: space-around;
    }
}

.upload_contanier {
    .title {
        font-size: 30px;

        span {
            color: red;
        }
    }

    .time {
        font-size: 30px;
        text-align: right;
    }
}

.vidieos {
    position: fixed;
    right: 0px;
    top: 200px;
}

.canvas {
    display: none;
}

.finishUp {
    margin-top: 30px;
    margin-bottom: 30px;
}

.editor {
    text-align: left;
    // height: auto;
    // width: 700px;
    // height: 100px;
}

.CodeMirror {
    border: 1px solid #eee !important;
    height: auto !important;
}

.CodeMirror-scroll {
    height: auto !important;
    overflow-y: hidden !important;
    overflow-x: auto !important;
}

